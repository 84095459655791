import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/globals/interceptors/auth-guard.service';

const routes: Routes = [
  {
    path: 'principal',
    loadChildren: () => import('./pagen/principal/principal.module').then( m => m.PrincipalPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pagen/pagen.module').then( m => m.PagenPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pagen/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'pagen',
    loadChildren: () => import('./pagen/pagen.module').then( m => m.PagenPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'licitaciones',
    loadChildren: () => import('./pagen/licitaciones/licitaciones.module').then( m => m.LicitacionesPageModule)
  },
  {
    path: 'recepcion',
    loadChildren: () => import('./pagen/recepcion/recepcion.module').then( m => m.RecepcionPageModule)
  },
  {
    path: 'encuestavirus',
    loadChildren: () => import('./pagen/encuestavirus/encuestavirus.module').then( m => m.EncuestavirusPageModule)
  },
  {
    path: 'pqrs',
    loadChildren: () => import('./pagen/pqrs/pqrs.module').then( m => m.PqrsPageModule)
  },
  {
    path: 'encuestavirusdos',
    loadChildren: () => import('./pagen/encuestavirusdos/encuestavirusdos.module').then( m => m.EncuestavirusdosPageModule)
  },
  {
    path: 'solicitudcertificado',
    loadChildren: () => import('./pagen/solicitudcertificado/solicitudcertificado.module').then( m => m.SolicitudcertificadoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
