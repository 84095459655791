import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public url = environment.apiUrl + 'Usuarios/';
  public menu: Observable<any[]>;

  constructor(private http: HttpClient) { }

  getMenu$ = (): Observable<any> => {
    if (!this.menu) {
      this.menu = this.http.get<any>(this.url + 'Menu')
      .pipe(
        map(data => data.datos),     // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        catchError(error => of(console.log(error)))
      );
    }

    return this.menu;
  }

  clear() {
      this.menu = null;
  }
}
