import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsuariosService } from '../../../services/usuarios.service';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-modificarclave',
  templateUrl: './modificarclave.page.html',
  styleUrls: ['./modificarclave.page.scss'],
})
export class ModificarclavePage implements OnInit {
  @Input() resultado;
  public formGroupusuario: FormGroup;
  constructor(private formBuiladicion: FormBuilder,
              private modalcrt: ModalController,
              private loadingController: LoadingController,
              public toastController: ToastController,
              private serviusuario: UsuariosService) { }

  ngOnInit() {
    this.buildFormEncabezado();
    this.buscar();
  }

  buildFormEncabezado() {
    this.formGroupusuario = this.formBuiladicion.group({
      id:  ['', Validators.required],
      clave: ['', Validators.required]
    });
  }

  cerrar() {
    this.modalcrt.dismiss( );
  }

  buscar() {
      if (this.resultado != null) {
        this.formGroupusuario.get('id').setValue(this.resultado.id);
      } else {
        this.formGroupusuario.get('id').setValue(JSON.parse(localStorage.currentUser).id);
      }
 }

 async formcrear() {
  const toast = await this.toastController.create({
    message: 'Modificacion Exitosa',
    duration: 4000
  });
  const loading = await this.loadingController.create({
    message: 'Guardando información...',
  });
  loading.present();
  this.serviusuario.postambioClave$(this.formGroupusuario.value).subscribe(result => {
    if (result.estado === 'ok') {

      this.serviusuario.getListarAllTablerod$().subscribe(results => {
        this.serviusuario.setlistarall(results);
      });
      this.modalcrt.dismiss();
      loading.dismiss();
      toast.present();
    } else {
      loading.dismiss();
    }
  });
}
}
