import { Component, OnInit } from '@angular/core';
import {LoginService } from '../../services/login.service';
import { ModalController } from '@ionic/angular';
import { ModificarclavePage } from '../../pagen/usuarios/modificarclave/modificarclave.page';
import { UsuariosService } from '../../services/usuarios.service';
@Component({
  selector: 'app-popuser',
  templateUrl: './popuser.component.html',
  styleUrls: ['./popuser.component.scss'],
})
export class PopuserComponent implements OnInit {

  constructor(private logser: LoginService,
              private modalcrtl: ModalController,
              private serviuser: UsuariosService) { }

  ngOnInit() {}

  Closesesion() {
    this.logser.logout();
  }

  async modificarclave() {
    const modal = await this.modalcrtl.create({
      component: ModificarclavePage

  });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    this.serviuser.getListarAllTablerod$().subscribe(results => {
      this.serviuser.setlistarall(results);
    });
  }

}
