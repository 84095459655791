import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopuserComponent } from '../popuser/popuser.component';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public user = JSON.parse(localStorage.currentUser).nombres;
  public id = JSON.parse(localStorage.currentUser).id;
  @Input() titulo;
  constructor(public popoverController: PopoverController,
              private logser: LoginService) { }

  ngOnInit() {
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopuserComponent,
      event: ev,
      mode: 'ios'
      // translucent: true
    });
    return await popover.present();
  }

  Closesesion() {
    this.logser.logout();
  }


}






