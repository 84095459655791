import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PopuserComponent } from './popuser/popuser.component';
import { ModificarclavePage } from '../pagen/usuarios/modificarclave/modificarclave.page';
import { ModificarclavePageModule } from '../pagen/usuarios/modificarclave/modificarclave.module';
import { TooltipDirective } from '../shared/globals/directives/tooltip.directive';
import { TablerocontrolPageModule } from '../pagen/tablerocontrol/tablerocontrol.module';



@NgModule({
  entryComponents: [
  PopuserComponent,
  ModificarclavePage
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    PopuserComponent
  ],
  exports: [
     HeaderComponent,
     MenuComponent,
     PopuserComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ModificarclavePageModule
  ]
})
export class ComponentsModule { }
