import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { filter, tap, catchError } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { LoginService } from '../../../services/login.service';

@Injectable()
export class InterceptorsService  implements HttpInterceptor  {

  constructor(
    public toastController: ToastController,
    private loginService: LoginService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    return next.handle(req).pipe(
      filter((event: HttpEvent<any>) => event instanceof HttpResponse),
      tap((resp: HttpResponse<any>) => this.auditEvent(resp, startTime)),
      catchError(err => this.error(err))
    );
  }

  private auditEvent(resp: HttpResponse<any>, startTime: number) {
    const elapsedMs = Date.now() - startTime;
    const eventMessage = resp.body;

    if (eventMessage.estado) {
      const message = eventMessage.estado + ' ' + eventMessage.mensaje + ' in ' + elapsedMs + 'ms';

      if (eventMessage.estado !== 'ok') {
        this.presentToast(eventMessage.mensaje);
      }
    }
  }

  private error(err) {
    if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.loginService.logout();
    }

    const error = err.message || err.statusText;

    console.log(error);

    return throwError(error);
  }

  async presentToast(textMessage: string) {
    const toast = await this.toastController.create({
      message: textMessage,
      duration: 2000
    });
    toast.present();
  }
}
