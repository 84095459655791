import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MenuService } from '../../services/menu.service';
import { Menu } from '../../interfaces/menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public user = JSON.parse(localStorage.currentUser).nombres;
 menu: Menu[];


 componentes: Menu[] = [
  {
    icon: 'american-football',
    name: 'Uno',
    redirectto: '/'
  },
  {
    icon: 'appstore',
    name: 'dos',
    redirectto: '/'
  }

];
  constructor(private menuservi: MenuService,
              private menuctrl: MenuController
            ) { }

  ngOnInit() {
    this.menuprincipal();
  }

  menuprincipal() {
    this.menuservi.getMenu$().subscribe(menu => this.menu = menu);
   }

}
