import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Usuario} from '../interfaces/usuarios';
import {Perfiles} from '../interfaces/perfiles';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class UsuariosService {
  public url = environment.apiUrl + 'Usuarios/';
  private ListarAllUsuarios = new  BehaviorSubject<any>({});
  public usuarios: Observable<any[]>;
  public perfiles: Observable<any[]>;
  constructor(private http: HttpClient) { }

  public postCrear$ = (usuarios: Usuario): Observable<Usuario> =>
  this.http.post<Usuario>(this.url + 'Crear', usuarios, httpOptions)
  .pipe(
    map(data => data),
    catchError(error => of(null))
  )

  public postambioClave$ = (usuarios: Usuario): Observable<Usuario> =>
  this.http.post<Usuario>(this.url + 'CambioClaveUsuario', usuarios, httpOptions)
  .pipe(
    map(data => data),
    catchError(error => of(null))
  )

  public postListarUsuarioId$ = (usuarios: Usuario): Observable<Usuario> =>
  this.http.post<Usuario>(this.url + 'ListarUsuarioId', usuarios, httpOptions)
  .pipe(
    map(data => data),
    catchError(error => of(null))
  )

  public postEliminarUsuario$ = (usuarios: Usuario): Observable<Usuario> =>
  this.http.post<Usuario>(this.url + 'EliminarUsuario', usuarios, httpOptions)
  .pipe(
    map(data => data),
    catchError(error => of(null))
  )

  public getListarAllTablerod$ = (): Observable<any> => {
    if (!this.usuarios) {
      this.usuarios = this.http.get<any>(this.url + 'ListarUsuario')
      .pipe(
        map(data => data.datos),     // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        catchError(error => of(console.log(error)))
      );
    }
    return this.usuarios;
  }

  getlistarall() {
    return this.ListarAllUsuarios.asObservable();
  }

  setlistarall(usuarios: any) {
    this.ListarAllUsuarios.next(usuarios);
  }

// listar perfiles

public getListarPerfiles$ = (): Observable<any> => {
  if (!this.perfiles) {
    this.perfiles = this.http.get<any>(this.url + 'ListarPerfil')
    .pipe(
      map(data => data.datos),     // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      catchError(error => of(console.log(error)))
    );
  }
  return this.perfiles;
}


}
