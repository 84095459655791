import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Login } from '../interfaces/login';
import { Router } from '@angular/router';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url = environment.apiUrl + 'Login/';

  constructor(    private http: HttpClient,
                  private router: Router) { }

  public get currentUserValue(): Login {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  // tslint:disable-next-line: max-line-length
  public login$ = (login: Login): Observable<Login> => this.http.post<any>(this.url + 'Autenticacion', login, httpOptions)
  .pipe(
    map(data => {
      const log = data.datos;

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(log));

      return log;
    }),
    catchError(error => of(error))
  )

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    // Va a Login
    this.router.navigate(['/login']);
  }


}
